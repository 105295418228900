<template>
  <nav class="main-nav" :class="{'main-nav-scrolled': isScrolled, 'main-nav-current-order':user && user.currentOrder && merchant.slug === user.currentOrder.merchantSlug && this.$route.name === 'merchant'}">
    <div class="container nav-content">
      <router-link :to="{name:'merchant', params:{merchantSlug:$route.params.merchantSlug}}" class="merchant">
        <img :src="merchant.logo.path" alt="">
        <div class="info">
          <strong>{{merchant.name}}</strong>
          <span v-if="merchant.subtitle">{{merchant.subtitle}}</span>
        </div>
      </router-link>

      <div class="product-categories-container">
        <div class="product-categories">
          <div class="category" v-for="category in menu">{{category.title}}</div>
        </div>
      </div>

      <div class="merchant-info" v-if="!minified" @click="$root.$emit('openMapPanel')">
        <i class="material-icons notranslate">place</i>
        <span>{{merchant.address.address}}, {{merchant.address.city}}</span>
      </div>

      <a :href="'tel:' + merchant.phone" class="merchant-info merchant-info-phone" v-if="!minified && merchant.phone">
        <i class="material-icons notranslate">phone</i>
        <span>{{merchant.phone}}</span>
      </a>

      <div class="merchant-opening-status" @click="$root.$emit('openHours')" v-if="merchant.activated">
        <span class="open"   @click="$root.$emit('openHours')" v-if="merchant.shiftsOnSite.open && merchant.activated">{{ $t('header_open', {hours: merchant.shiftsOnSite.closeAt}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!exceptional && !merchant.shiftsOnSite.open && merchant.shiftsOnSite.active && merchant.shiftsOnSite.openAt === null">{{ $t('header_closed') }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!exceptional && !merchant.shiftsOnSite.open && merchant.shiftsOnSite.active && merchant.shiftsOnSite.openAt !== null">{{ $t('header_closed_until', {time:merchant.shiftsOnSite.openAt}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="exceptional && !merchant.shiftsOnSite.exceptionalHours.today">{{ $t('header_closed_vacation', {date: merchant.shiftsOnSite.exceptionalHours.endDate}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="exceptional && merchant.shiftsOnSite.exceptionalHours.today">{{ $t('header_closed_vacation_today', {hours: merchant.shiftsOnSite.exceptionalHours.endDateHour}) }}</span>
        <span class="closed" @click="$root.$emit('openHours')" v-if="!merchant.shiftsOnSite.active">{{ $t('order_no_available', {merchant:merchant.name}) }}</span>
      </div>

      <div v-if="!merchant.activated" class="merchant-opening-status">
        <span class="closed" @click="$root.$emit('openHours')">{{ $t('merchant_disabled') }}</span>
      </div>

      <div class="nav-cart" v-if="merchant.activated && this.cart.type !== 'on_site'">
        <div class="cart-button" @click="openCart" ref="cartButton">
          <div class="counter" v-if="cart.quantity > 0">{{cart.quantity}}</div>
          <img src="../assets/delicity_bag.svg">
        </div>
        <app-nav-cart></app-nav-cart>
      </div>

      <div class="nav-cart" v-if="merchant.activated && this.cart.type === 'on_site'">
        <div class="cart-button" style="opacity: 0.5">
          <div class="counter" v-if="cart.quantity > 0">{{cart.quantity}}</div>
          <img src="../assets/delicity_bag.svg">
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import NavCartButton from './Merchant/NavCartButton'
import NavCart from "@/desktopComponents/NavCart";
import Cart from "@/desktopComponents/Merchant/Cart";
import {$vfm} from "vue-final-modal";
import CartDesktop from "@/components/Modals/Cart/CartDesktop";

export default {
  components: {
    'app-nav-cart-button': NavCartButton,
    'app-nav-cart': NavCart
  },
  props: {
    minified: {
      default: false,
      type: Boolean
    }
  },
  data(){
    return {
      isScrolled: false
    }
  },
  computed: {
    ...mapGetters(['merchant', 'cart', 'menu', 'user']),
    exceptional(){
      return !this.merchant.shifts.open && this.merchant.shifts.exceptionalHours;
    }
  },
  methods: {
    scroll(){
      this.isScrolled = window.scrollY > 10;
    },
    openCart(){
      $vfm.show({component: CartDesktop});
      window.history.pushState({}, "",location.pathname + '#cart');
    }
  },
  mounted() {
    this.scroll();
    window.document.body.onscroll = () => {
      this.scroll();
    };
  }
}
</script>
