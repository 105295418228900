<template>

  <div>
    <div class="alert alert-danger my-0" v-if="!cart.deliveryAvailable && cart.deliveryClosedReason === 'closed_courier'">
      <div><strong>Pas de livreur à proximité.</strong></div>
      <span>Il n'y actuellement pas suffisamment de livreurs connectés dans la zone, le click and collect reste disponible.</span>
    </div>
    <div class="alert alert-danger my-0" v-if="!cart.deliveryAvailable && cart.deliveryClosedReason === 'closed_hours'">
      <div><strong>Livraison indisponible</strong></div>
      <span v-if="isMorning">Il est trop tôt pour se faire livrer... La commande à emporter est disponible</span>
      <span v-else>Il est trop tard pour se faire livrer... La commande à emporter reste disponible</span>
    </div>
  </div>

</template>

<style lang="scss">
  .alert
  {
    border: 0;
  }
</style>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters(['cart']),
    isMorning(){
      const hour = moment().format("HH");
      return hour > 2 && hour <= 12;
    }
  }
}
</script>
