<template>

  <div class="container onboarding auth-page">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <ui-alert :dismissible="false" class="mb-0">{{$t('auth_usage')}}</ui-alert>
        <ui-alert type="error" v-if="error" @dismiss="error = false">{{error}}</ui-alert>

        <div class="row mt-4">
          <div class="col-12 mb-3">
            <label>{{$t('auth_label_firstname')}}</label>
            <input type="text" class="form-control" v-model="userData.firstname">
          </div>
          <div class="col-12 mb-3">
            <label>{{$t('auth_label_lastname')}}</label>
            <input type="text" class="form-control" v-model="userData.lastname">
          </div>
          <div class="col-12 mb-3">
            <label>{{$t('auth_label_email')}}</label>
            <input type="text" class="form-control" disabled :value="user.email">
          </div>
          <div class="col-12 mb-3">
            <label>{{$t('auth_label_phone')}}</label>
            <vue-tel-input v-model="userData.phone" default-country="fr" mode="international" :placeholder="$t('auth_login_placeholder_phone')"></vue-tel-input>
            <div style="line-height: 1.1" class="mt-2">
              <small>{{$t('check_your_phone')}}</small>
            </div>
          </div>
          <div class="col-12 mt-4">
            <ui-button color="primary" class="w-100" @click="checkPhoneTrigger()" :loading="isLoading" size="large">{{$t('auth_save')}}</ui-button>
          </div>
        </div>




      </div>
    </div>

    <div class="validate-phone-container" v-if="checkPhone">
      <div class="validate-phone">
        <span>{{$t('check_phone')}}</span>
        <strong>{{userData.phone}}</strong>
        <ui-button class="w-100" color="primary" :loading="isLoading" @click="update">{{$t('is_my_phone')}}</ui-button>
        <ui-button class="w-100" color="clear" @click="checkPhone = false">{{$t('i_edit_my_phone')}}</ui-button>
      </div>
    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import PanelTemplate from "@/mobileComponents/PanelTemplate";

export default {
  data(){
    return {
      userData: {
        firstname: null,
        lastname: null,
        phone: null,
      },
      error: false,
      isLoading: false,
      checkPhone: false
    }
  },
  components: {
    'app-panel': PanelTemplate
  },
  methods:{
    ...mapActions(['onboardingAccountSave']),
    update(){
      if(this.userData.firstname === null || this.userData.lastname === null || this.userData.phone === null){
        this.error = this.$t('auth_error');
        return;
      }
      this.isLoading = true;
      this.onboardingAccountSave(this.userData)
          .then(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            this.error = error;
            this.isLoading = false;
            this.checkPhone = false;
          });
    },
    checkPhoneTrigger() {
      this.error = false;
      if(!this.userData.phone){
        this.error = this.$t('phone_is_required');
        return;
      }
      this.checkPhone = true;
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.checkPhone = false;
    this.userData = {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      phone: this.user.phone,
    }
  }
}
</script>
