<template>
  <ui-icon-button
      color="black"
      class="card-button"
      size="large"
      type="secondary"
      @click="openCart"
      v-if="cart">
    <i class="material-icons notranslate">shopping_cart</i>
    <small v-if="cart.quantity > 0">{{cart.quantity}}</small>
  </ui-icon-button>
</template>



<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    openCart(){
      this.$root.$emit('closeProduct');
      this.$root.$emit('openCart');
    }
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
