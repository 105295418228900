<template>
  <app-panel type="panel" event-name="openPlatformFeeInfo" title="En savoir plus sur les frais" hide-validate-button>
    <div class="panel-body panel-text">
      <div class="p-4">

        <div class="mb-3">
          <h5>A quoi correspondent les frais de service ?</h5>
          <p>{{ merchant.name }} est un restaurant partenaire du réseau Delicity.</p>
        </div>

        <p>
          Delicity est un service qui permet à vos restaurants préférés de proposer la livraison tout en restant indépendants avec des frais de service de {{cart.values.platformFees.price}} seulement.
          Ce montant peut varier selon plusieurs critères comme votre zone géographique, le restaurant dans lequel vous commandez ou encore votre fidélité.
        </p>

        <p>
          Les frais de livraison sont quand à eux entièrement destinés aux livreurs du restaurant ou aux livreurs indépendants. Les frais de livraison affichés correspondent à la partie que les restaurateurs n’ont pas souhaité payer.
        </p>

        <h5 class="mt-4">Le Saviez vous ?</h5>
        <p>Les plateformes traditionnelles prennent des commissions exorbitantes aux restaurateurs et l’addition peut être donc jusqu’à 30% plus élevée.</p>
        <p>Pas nous.</p>

        <h5 class="mt-4">Pourquoi Delicity ?</h5>

        <p>Nous avons mis en place un service dans lequel tout le monde y gagne :</p>
        <ul>
          <li>Vous faites des economies en passant commande</li>
          <li>Le restaurant gagne plus et garde le controle de son activité</li>
          <li>Le livreur est payé en moyenne 15% de plus</li>
        </ul>

        <p>Pour en savoir plus, rendez vous sur <a href="https://pro.delicity.co" target="_blank">pro.delicity.co</a></p>

      </div>
    </div>
  </app-panel>
</template>

<script>
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import { mapGetters } from 'vuex'

export default {
  components: {
    'app-panel': PanelTemplate
  },
  computed: {
    ...mapGetters(['merchant', 'cart'])
  }
}
</script>
