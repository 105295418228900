<template>
  <app-panel type="panel"
             class="address-list-modal"
             event-name="openAddressList"
             ref="addressListModal"
             :title="$t('address_delivery')"
             :hide-validate-button="selectedAddress === null"
             :validate-button="$t('choose_this_address')"
             @validated="validateAddress">

    <div class="row no-gutters">
      <div class="col-12">
        <div class="add-address-autocomplete" ref="addressInput">

          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" version="1" viewBox="0 0 24 24" fill="black" width="25" height="25">
            <path d="M12,2c4.2,0,8,3.22,8,8.2c0,3.32-2.67,7.25-8,11.8c-5.33-4.55-8-8.48-8-11.8C4,5.22,7.8,2,12,2z M18,10.2 C18,6.57,15.35,4,12,4s-6,2.57-6,6.2c0,2.34,1.95,5.44,6,9.14C16.05,15.64,18,12.54,18,10.2z M12,12c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S13.1,12,12,12z" fill="#FE2E00"/>
          </svg>

          <vue-google-autocomplete
              id="map2"
              classname="form-control autocomplete-address"
              :placeholder="$t('add_new_address')"
              enable-geolocation
              country="fr"
              ref="addressAutocomplete2"
              v-on:placechanged="addAdress">
          </vue-google-autocomplete>

        </div>
      </div>
    </div>


    <div class="address-list-container scroll" v-if="addresses.length !== 0">
      <div class="address-title">
        <h6>{{$t('your_addresses')}}</h6>
      </div>
      <div class="address-block" :class="{'address-block-selected':selectedAddress === address.id}" v-for="address in addresses" @click="selectAddress(address)">
        <div class="icon">
          <i class="material-icons notranslate" v-if="address.name">star</i>
          <i class="material-icons notranslate" v-if="!address.name">schedule</i>
        </div>
        <div class="info">
          <strong v-if="address.name">{{address.name}}</strong>
          <span>{{address.address}}</span>
          <span v-if="address.address2">{{address.address2}}</span>
          <span>{{address.zip}} {{address.city}}</span>
          <div class="address-meta">
            <span v-if="address.houseName">{{address.houseName}}</span>
            <span v-if="address.houseInfo">{{address.houseInfo}}</span>
            <span class="w-100" v-if="address.comment">{{address.comment}}</span>
          </div>

        </div>
        <div class="select">
          <i class="material-icons notranslate">check</i>
        </div>
      </div>
    </div>

  </app-panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import tippy from 'tippy.js/esm';

export default {
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
    'app-panel': PanelTemplate
  },
  data(){
    return {
      address: null,
      selectedAddress: null,
      addresses: [],
    }
  },
  methods: {
    ...mapActions(['addOrderAddress', 'setOrderAddress']),
    close(){
      if(this.cart.cartDelivery && this.cart.cartDelivery.addressDefined && this.selectedAddress){
        if(this.cart.cartDelivery.address.id !== this.selectedAddress){
          this.setOrderAddress(this.selectedAddress);
        }
      }
      else {
        this.setOrderAddress(this.selectedAddress);
      }
    },
    addAdress(data){
      this.address = data;
      if(this.address === null){
        this.error = this.$t('order_address_error_empty');
        return;
      }

      if(!this.address.street_number){
        tippy(this.$refs.addressInput, {
          content: this.$t('order_address_error_not_street_number'),
          placement: 'bottom',
          arrow: 'default',
          trigger: 'focus'
        });
        this.$refs.addressInput.focus();
      }

      if( !this.address.street_number ||
          !this.address.route ||
          !this.address.postal_code ||
          !this.address.locality ||
          !this.address.country ||
          !this.address.latitude ||
          !this.address.longitude
      ){
        tippy(this.$refs.addressInput, {
          content: this.$t('order_address_error_not_valid'),
          placement: 'bottom',
          arrow: 'default',
          trigger: 'focus'
        });
        this.$refs.addressInput.focus();
        return;
      }

      this.isLoading = true;

      this.addOrderAddress(this.address)
          .then((response) => {
            this.isLoading = false;
            this.address = null;

            this.$root.$emit('openAddressEditor', response)
            setTimeout(() => {
              this.$refs.addressAutocomplete2.clear();
              //this.close();
            }, 1500)
          })
          .catch(() => {
            tippy(this.$refs.addressAutocomplete2, {
              content: this.$t('address_not_valid'),
              placement: 'bottom',
              arrow: 'default',
              trigger: 'focus'
            });
            this.$refs.addressAutocomplete2.focus();
          })
    },
    selectAddress(address){
      if(this.selectedAddress === address.id){ // Already selected -> update
        this.$root.$emit('openAddressEditor', address);
      }
      this.selectedAddress = address.id;
    },
    setAddress(){
      if(this.cart.cartDelivery && this.cart.cartDelivery.addressDefined){
        this.selectedAddress = this.cart.cartDelivery.address.id;
      }
      if(this.user){
        return this.addresses = this.user.addresses;
      }
      if(this.cart.cartDelivery && this.cart.cartDelivery.addressDefined){
        return this.addresses = [this.cart.cartDelivery.address];
      }
    },
    validateAddress(){
      this.$refs.addressListModal.close();
      this.close();
    }
  },
  computed: {
    ...mapGetters(['cart', 'user'])
  },
  watch: {
    cart: {
      deep: true,
      handler(){
        this.setAddress();
      }
    }
  },
  mounted() {
    this.setAddress();
    if(this.cart.cartDelivery && this.cart.cartDelivery.addressDefined){
      this.selectedAddress = this.cart.cartDelivery.address.id;
    }
  }
}
</script>
