<template>
  <div class="comment">
    <textarea maxlength="250" :placeholder="$t('cart_comment_placeholder')" class="form-control" v-model="comment"></textarea>
    <div class="text-right p-1">
      <span class="text-muted" v-if="comment">{{ comment.length }}/250</span>
    </div>
    <div class="actions">
      <ui-button color="primary" size="small" @click="save">{{$t('cart_comment_validate')}}</ui-button>
      <ui-button color="clear" @click="$root.$emit('cancelCartComment')">{{$t('cancel')}}</ui-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PanelTemplate from "@/desktopComponents/PanelTemplate";

export default {
  data(){
    return {
      comment: ''
    }
  },
  components: {
    'app-panel': PanelTemplate
  },
  methods: {
    ...mapActions(['setOrderComment']),
    save(){
      this.setOrderComment(this.comment)
          .then(() => {
            this.$root.$emit('cancelCartComment');
          });
    }
  },
  mounted() {
    this.comment = this.cart.comment;
  },
  computed: {
    ...mapGetters(['cart'])
  }
}
</script>
