<template>
  <div class="auth-form">
    <ui-alert type="error" v-if="error" @dismiss="error = false">{{error}}</ui-alert>
    <div class="form-modal-template">

      <div class="header-image-modal">
        <img src="../../assets/logo_square_XS.png">
      </div>

      <div class="form-group">
        <label>{{ $t('auth_register_label_firstname') }}</label>
        <input type="text" v-model="user.firstname" class="form-control" :class="{'is-invalid': errors.firstname}">
        <div v-if="errors.firstname">
          <small class="text-danger" v-for="error in errors.firstname">{{error}}</small>
        </div>
      </div>
      <div class="form-group">
        <label>{{ $t('auth_register_label_lastname') }}</label>
        <input type="text" v-model="user.lastname" class="form-control" :class="{'is-invalid': errors.lastname}">
        <div v-if="errors.lastname">
          <small class="text-danger" v-for="error in errors.lastname">{{error}}</small>
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('auth_login_label_email') }}</label>
        <input type="email" v-model="user.email" class="form-control" :class="{'is-invalid': errors.email}">
        <div v-if="errors.email">
          <small class="text-danger" v-for="error in errors.email">{{error}}</small>
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('auth_login_label_phone') }}</label>
        <vue-tel-input v-model="user.phone" default-country="fr" mode="international" :placeholder="$t('auth_login_placeholder_phone')"></vue-tel-input>
        <div v-if="errors.phone">
          <small class="text-danger" v-for="error in errors.phone">{{error}}</small>
        </div>
        <div style="line-height: 1.1" class="mt-2">
          <small>{{$t('check_your_phone')}}</small>
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('auth_login_label_password') }}</label>
        <input type="password" v-model="user.password" class="form-control" :class="{'is-invalid': errors.password}">
        <div v-if="errors.password">
          <small class="text-danger" v-for="error in errors.password">{{error}}</small>
        </div>
      </div>

      <div class="action-group">
        <ui-button color="primary" @click="registerButton" size="large" :loading="isLoading">{{ $t('auth_login_action_register') }}</ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import { mapActions } from 'vuex'

export default {
  data(){
    return {
      isLoading: false,
      user: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        password: null
      },
      error: false,
      errors: []
    }
  },
  components: {
    'app-panel': PanelTemplate
  },
  methods: {
    ...mapActions(['register']),
    registerButton(){

      if(!this.user.firstname
          || !this.user.lastname
          || !this.user.email
          || !this.user.phone
          || !this.user.password) {
        this.error = this.$t('missing_field');
        return;
      }

      this.register(this.user)
          .then((response) => {
            this.$root.$emit('closeRegister');
            localStorage.setItem('bearer', response.access_token);
            location.reload();
          })
          .catch((errors) => {
            this.errors = errors;
          });
    }
  }
}
</script>
