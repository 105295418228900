<template>
  <transition name="fade">
    <div class="nav-cart-popup" @click="openCart" v-if="show" :style="{'top':top +'px'}">
      <div class="nav-cart-popup-arrow">
        <div class="arrow"></div>
      </div>
      <div class="cart-header">
        <i class="material-icons notranslate" @click.prevent.stop="show = false">close</i>
        <span>{{$t('added_in_your_cart')}}</span>
      </div>

      <div class="product-cart-row" v-if="product">
        <div class="quantity">
          <span>{{quantity}}</span>
        </div>
        <div class="info">
          <strong>{{ product.name }}</strong>
          <span></span>
        </div>
        <strong class="price">{{price}}</strong>
      </div>

      <ui-button color="green">{{$t('see_cart')}}</ui-button>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex';
import {$vfm} from "vue-final-modal";
import CartDesktop from "@/components/Modals/Cart/CartDesktop.vue";

export default {
  data(){
    return {
      show: false,
      product: false,
      price: 0,
      quantity: 0,
      top: 120
    }
  },
  methods: {
    openCart(){
      $vfm.show({component: CartDesktop});
      window.history.pushState({}, "",location.pathname + '#cart');
    },
    listen(){
      this.$root.$on('addToCart', ({product, quantity}) => {
        this.getTop();
        this.show = true;
        this.product = product;
        this.quantity = quantity;

        this.getTotalPrice();

        setTimeout(() => {
          this.show = false;
        }, 2000)
      });
    },
    getTotalPrice(){
      let optionPrice = 0;
      this.selectedOptions = [];

      this.product.options.forEach((option) => {
        option.values.forEach((value) => {
          if(value.checked && !option.quantityEnabled){
            optionPrice += value.price.value;
            this.selectedOptions.push({optionId: option.id, valueId: value.id, quantity: 1});
          }
          if(option.quantityEnabled && value.quantity > 0){
            optionPrice += value.price.value * value.quantity;
            this.selectedOptions.push({optionId: option.id, valueId: value.id, quantity: value.quantity});
          }
        });
      });

      let price;

      if(this.cart.type === 'on_site' || this.cart.type === 'on_site_counter'){
        price = (this.product.priceOnsite.value + optionPrice) * this.quantity;
      }
      if(this.cart.type === 'pickup'){
        price = (this.product.pricePickup.value + optionPrice) * this.quantity;
      }
      if(this.cart.type === 'delivery'){
        price = (this.product.priceDelivery.value + optionPrice) * this.quantity;
      }
      let formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      });
      this.price  = formatter.format(price / 100);
    },
    getTop(){
      let nav = document.querySelector('.main-nav');
      this.top = nav.offsetTop + nav.offsetHeight + 10;
    }
  },
  computed: {
    ...mapGetters(['cart'])
  },
  mounted() {
    this.listen();
  }
}
</script>
