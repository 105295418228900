<template>
  <div class="container auth-page">
    <div class="row justify-content-center">

      <div class="col-lg-12">
         <ui-icon-button icon="keyboard_backspace" size="large" color="clear" class="back-action" v-if="page !== 'main'" @click="page = 'main'"></ui-icon-button>
         <ui-icon-button icon="keyboard_backspace" size="large" color="clear" class="back-action" v-if="page === 'main'" @click="$router.push({name:'merchant', params:{merchantSlug:$route.params.merchantSlug}})"></ui-icon-button>
      </div>

      <div class="col-lg-6">
        <div class="auth-container" v-if="page === 'main'">
          <strong class="auth-title">{{$t('your_account')}}</strong>

          <div class="mb-4" v-if="enabledLoginMethodsLoading">
            <ui-progress-circular color="black"/>
          </div>

          <button class="auth-button auth-google" @click="connect('google')" v-if="!enabledLoginMethodsLoading && enabledLoginMethods.google">
            <div class="side icon">
              <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg"><path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4"/><path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853"/><path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04"/><path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335"/></svg>
            </div>
            <span>{{$t('auth_google')}}</span>
            <div class="side"></div>
          </button>

          <button class="auth-button auth-facebook" @click="connect('facebook')" v-if="!enabledLoginMethodsLoading && enabledLoginMethods.facebook">
            <div class="side icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg>
            </div>
            <span>{{ $t('auth_facebook') }}</span>
            <div class="side"></div>
          </button>

          <button class="auth-button auth-delicity" @click="page = 'login'">
            <div class="side icon">
              <img src="../../assets/logo_square_XS.png" alt="">
            </div>
            <span>{{$t('continue_with_delicity')}}</span>
            <div class="side"></div>
          </button>

          <button class="auth-button auth-no-account" @click="page = 'no_account'" v-if="merchant.options.anonymousOrder === true">
            <div class="side icon">
            </div>
            <span>{{$t('i_order_with_no_account')}}</span>
            <div class="side"></div>
          </button>
        </div>

        <div v-if="page === 'login'">
          <app-login @page="setPage"></app-login>
        </div>
        <div v-if="page === 'register'">
          <app-register @page="setPage"></app-register>
        </div>
        <div v-if="page === 'no_account'">
          <app-no-account @page="setPage"></app-no-account>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import config from "@/config";
import Login from "@/desktopComponents/User/Login";
import Register from "@/desktopComponents/User/Register";
import NoAccount from "@/desktopComponents/User/NoAccount";

export default {
  components: {
    'app-login': Login,
    'app-register': Register,
    'app-no-account': NoAccount
  },
  data(){
    return {
      page: 'main'
    }
  },
  methods: {
    ...mapActions(['getEnabledLoginMethods']),
    setPage(p){
      console.log(p);
      this.page = p;
    },
    listen(){
      this.$root.$on('openAuth', () => {
        this.open();
      });
      this.$root.$on('closeAuth', () => {
        this.close();
      });
    },
    open(){
      this.$refs.auth.open();
    },
    close(){
      this.$refs.auth.close();
    },
    connect(type){
      let redirect = encodeURI(location.origin + location.pathname + '?authed=true');
      if(type === 'facebook'){
        return window.location.href = config.login_url + '/login/facebook?redirect=' + redirect + '&userToken=' + this.userToken;
      }
      if(type === 'google'){
        return window.location.href = config.login_url + '/login/google?redirect=' + redirect + '&userToken=' + this.userToken;
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'userToken', 'merchant', 'enabledLoginMethods', 'enabledLoginMethodsLoading'])
  },
  mounted() {
    this.getEnabledLoginMethods();
    this.listen();
  }
}
</script>
