<template>
  <vue-final-modal name="cart-time"
                   v-bind="$attrs"
                   v-slot="{close}"
                   classes="vfm-container"
                   content-class="vfm-content vfm-content-xmedium cart-modal"
                   v-on="$listeners">

    <div slot="nav"></div>

    <div class="cart-header">
      <div class="cart-button">
        <img src="/img/delicity_bag.svg">
      </div>
      <span class="cart-title">{{$t('your_cart')}}</span>
      <i class="material-icons notranslate" @click="close">close</i>
    </div>

    <div class="cart-container d-flex align-items-center justify-content-center" style="height: 250px;" v-if="isCartLoading">
      <ui-progress-circular color="black"></ui-progress-circular>
    </div>

    <div class="cart-container" v-if="!isCartLoading && cart">

      <ui-alert type="error" id="cart_error" class="mb-0" v-if="error" @dismiss="error = false">{{error}}</ui-alert>


      <div class="cart-empty" v-if="cart.OrderProducts.length === 0">
        <i class="material-icons notranslate">receipt_long</i>
        <strong>{{$t('cart_empty')}}</strong>
        <span>{{$t('cart_empty_text')}}</span>
      </div>


      <div class="cart-content" v-if="cart.OrderProducts.length !== 0">
      <app-announcement position="cart" class="mb-2"></app-announcement>
        <div class="cart-products">
          <div class="cart-product-item" v-if="cart.OrderProducts.length !== 0" @click="openProduct(product)" v-for="product in cart.OrderProducts">
            <div class="quantity"><span>{{product.quantity}}</span></div>
            <div class="infos">
              <span>{{product.product.name}}</span>
              <small>{{product.option}}</small>
              <small class="comment" v-if="product.comment">{{$t('comment')}} : {{ product.comment }}</small>
            </div>
            <div class="price">
              <span>{{product.price.price}}</span>
              <i class="material-icons notranslate" @click.stop="removeCart({productId:product.product.id, tid:product.optionIdentifier})">delete</i>
            </div>
          </div>
        </div>

        <div class="cart-comment-row" v-if="cart.type !== 'on_site' && !showComment" @click="showComment = true">
          <div v-if="!cart.comment" class="comment">
            <i class="material-icons notranslate">info</i>
            <span>{{ $t('add_cart_comment') }}</span>
          </div>
          <div v-if="cart.comment" class="comment">
            <i class="material-icons notranslate">info</i>
            <b>{{$t('comment_kitchen')}} :</b>
            <span>{{ cart.comment }}</span>
          </div>
        </div>

        <div v-if="showComment" class="card-comment-container">
          <app-cart-comment></app-cart-comment>
        </div>


        <div class="row">
          <div class="col-lg-6">

          </div>
          <div class="col-lg-6">

            <div class="cart-prices" v-if="cart.type === 'delivery'">



              <div class="price-row">
                <span>{{ $t('cart_price_sub_total') }}</span>
                <span>{{cart.values.totalProduct.price}}</span>
              </div>

              <template v-if="needUnlock">
                <div class="unlock-long-distance">
                  <div class="description">{{$t('cart_small_order_unlock_description', {price: cart.values.merchantUnlockSmallOrderPrice.price})}}</div>
                  <div class="actions">
                    <button @click="$router.push({name:'merchant', params:{merchantSlug:$route.params.merchantSlug}})">
                      <i class="material-icons notranslate">add_shopping_cart</i>
                      <span>{{$t('cart_small_order_unlock_add_product')}}</span>
                    </button>
                    <button  @click="unlock()">
                      <i class="material-icons notranslate">login</i>
                      <span>{{$t('cart_small_order_unlock_unlock', {price:cart.values.merchantSmallOrderSupplement.price})}}</span>
                    </button>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.value.value !== 0 && discount.type !== 'delivery'">
                  <button v-if="discount.dismissible" class="discount-remove-button" @click="removeDiscount(discount.discountId)"><i class="material-icons notranslate">close</i></button>
                  <span class="discount-name">{{discount.message}}</span>
                  <span>-{{discount.value.price}}</span>
                </div>
                <div class="price-row" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && !cart.cartDiscounts.find(d => d.type === 'delivery')">
                  <span v-if="cart.values.deliveryFees.value">{{ $t('cart_price_delivery')}}<b class="pl-1">({{cart.cartDelivery.distance.distance}})</b><i class="material-icons notranslate">info</i></span>
                  <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
                  <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_price_delivery_free') }}<i class="material-icons notranslate">info</i></span>
                  <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
                </div>
                <div class="price-row discount price-row-cursor" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && cart.cartDiscounts.find(d => d.type === 'delivery')">
                  <span v-if="cart.values.deliveryFees.value">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }} <i class="material-icons notranslate">info</i></span>
                  <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
                  <span v-if="cart.values.deliveryFees.value === 0">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }}<i class="material-icons notranslate">info</i></span>
                  <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
                </div>
                <div class="price-row" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && !cart.cartDelivery.addressDefined">
                  <span>{{ $t('cart_price_delivery')}}</span>
                  <a href="#" class="text-danger" @click.prevent="$root.$emit('openAddressList')">{{$t('cart_set_address')}}</a>
                </div>
                <div class="price-row" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && !cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
                  <span>{{ $t('cart_price_delivery') }}</span>
                  <span>--</span>
                </div>
                <div class="price-row" ref="addressError" v-if="cart.cartDelivery && cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
                  <span>{{ $t('cart_price_delivery') }}</span>
                  <span><i class="material-icons notranslate error">error_outline</i></span>
                </div>
                <div class="price-row price-row-light" v-if="cart.values.merchantLongDistanceSupplement && cart.values.merchantLongDistanceSupplement.value !== 0">
                  <span>+ {{ $t('cart_price_long_distance_supplement') }}</span>
                  <span>{{cart.values.merchantLongDistanceSupplement.price}}</span>
                </div>
                <div class="price-row price-row-light" v-if="cart.values.merchantSmallOrderSupplement && cart.values.merchantSmallOrderSupplement.value !== 0">
                  <span>+ {{ $t('cart_price_small_order_supplement') }}</span>
                  <span>{{cart.values.merchantSmallOrderSupplement.price}}</span>
                </div>
                <div class="price-row" @click="$root.$emit('openPlatformFeeInfo')">
                  <span>{{ $t('cart_price_platform_fee') }} <i class="material-icons notranslate">info</i></span>
                  <span v-if="cart.values.platformFees.value">{{cart.values.platformFees.price}}</span>
                  <span v-if="cart.values.platformFees.value === 0">{{ $t('cart_gifted') }}</span>
                </div>
                <div class="price-row discount" v-if="cart.values.discount.value !== 0">
                  <span>{{ $t('receipt_discount') }}</span>
                  <span v-if="cart.values.discount.value">-{{cart.values.discount.price}}</span>
                  <span v-if="cart.values.discount.value === 0">{{ $t('cart_gifted') }}</span>
                </div>


                <div class="price-row total">
                  <span>{{ $t('cart_price_total') }}</span>
                  <span>{{cart.values.total.price}}</span>
                </div>
              </template>

            </div>

            <div class="cart-prices" v-if="cart.type === 'on_site' || cart.type === 'on_site_counter' || cart.type === 'pickup'">
              <div class="price-row total" v-if="cart.cartDiscounts.length > 0">
                <span>{{ $t('cart_price_sub_total') }}</span>
                <span>{{cart.values.totalProduct.price}}</span>
              </div>
              <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.value.value !== 0">
                <button v-if="discount.dismissible" class="discount-remove-button" @click="removeDiscount(discount.discountId)"><i class="material-icons notranslate">close</i></button>
                <span class="discount-name">{{discount.message}}</span>
                <span>-{{discount.value.price}}</span>
              </div>
              <div class="price-row total">
                <span>{{ $t('cart_price_total') }}</span>
                <span>{{cart.values.total.price}}</span>
              </div>
            </div>


          </div>
        </div>


      </div>
    </div>


    <div slot="footer">
      <div class="modal-fixed-bottom-button" v-if="cart.OrderProducts.length !== 0 && cart.type !== 'on_site'">
        <ui-button size="large" v-if="!merchant.shifts.open && merchant.activated && !cart.laterOrder" color="green" :loading="isLoading" disabled class="cart-button">{{ $t('cart_order_btn_closed') }}</ui-button>

        <ui-button size="large"
                   :disabled="needUnlock"
                   v-if="merchant.shifts.open && merchant.activated || cart.laterOrder"
                   color="green"
                   :loading="isLoading"
                   :href="summary"
                   class="cart-button w-100"
        >{{ $t('continue_to_payment') }}</ui-button>

        <ui-button size="large" v-if="!merchant.activated" color="green" :loading="isLoading" disabled class="cart-button">{{ $t('no_avaible') }}</ui-button>
      </div>
      <div class="modal-fixed-bottom-button" v-if="cart.OrderProducts.length !== 0 && cart.type === 'on_site'">
        <ui-button size="large" color="default" disabled class="cart-button">{{ $t('on_site_payment') }}</ui-button>
      </div>
    </div>

  </vue-final-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import PanelTemplate from "@/desktopComponents/PanelTemplate";
import tippy from 'tippy.js/esm';
import 'tippy.js/index.css'
import CartComment from "@/desktopComponents/Merchant/CartComment";
import Announcement from "@/mobileComponents/Merchant/Announcement";
import {$vfm} from "vue-final-modal";
import EditProduct from "@/components/Modals/Product/EditProduct";

export default {
  name: "CartDesktop",
  components: {
    'app-panel': PanelTemplate,
    'app-cart-comment': CartComment,
    'app-announcement': Announcement
  },
  data(){
    return {
      isOpened: false,
      isLoading: false,
      stopBack: false,
      error: false,
      addressToolTip: false,
      showComment: false,
      isUnlock: true
    }
  },
  methods: {
    ...mapActions(['removeCart', 'removeDiscountCode']),
    close(){
      $vfm.hide('cart-desktop');
    },
    listen(){
      this.$root.$on('cancelCartComment', () => {
        this.showComment = false;
      })
    },
    openProduct(cartProduct){
      if(cartProduct.selectedValuesId.length !== 0){
        cartProduct.product.options.forEach((o) => {
          o.values.forEach((v) => {
            if(cartProduct.selectedValuesId[v.id] !== undefined){
              v.checked = true;
              v.quantity = cartProduct.selectedValuesId[v.id];
            }
          });
        });
      }
      this.close();
      $vfm.show({component: EditProduct, bind: {cartProduct}});
      window.history.pushState({}, "",location.pathname + '#update');
    },
    removeDiscount(id) {
      this.removeDiscountCode(id);
    },

    unlock(){
      this.isUnlock = true;
      localStorage.setItem(this.merchant.slug + '-unlock-' + this.cart.id, '1');
    }
  },
  mounted() {
    console.log(this.cart);

    this.listen();
    if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
      this.isUnlock = false;
    }
  },
  watch: {
    cart(){
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
        this.isUnlock = false;
      }
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value === 0){
        localStorage.removeItem(this.merchant.slug + '-unlock-' + this.cart.id);
      }
    }
  },
  computed: {
    ...mapGetters(['cart', 'user', 'merchant', 'orderToken', 'isCartLoading', 'menu']),
    summary(){
      if(window.location.host === 'delicity.com' || window.location.host === 'localhost:8080' || window.location.host === 'delicity.tech'){
        return '/'+this.merchant.slug + '/summary';
      }
      return '/summary';
    },
    needUnlock(){
      return this.cart &&
          this.cart.type === 'delivery' &&
          this.cart.values.merchantUnlockSmallOrderPrice &&
          this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 &&
          !this.isUnlock;
    }
  }
}
</script>
